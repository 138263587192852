<!--
 * @Author: mulingyuer
 * @Date: 2021-07-06 15:25:55
 * @LastEditTime: 2022-01-04 17:48:37
 * @LastEditors: aleaner
 * @Description: 证书管理
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\cert-manage\views\manage.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page manage">
    <!-- <div class="alert">
      <div class="alert-item">
        <i class="el-icon-sunny alert-pic"></i>
        <h2 class="alert-title">温馨提示</h2>
        <div class="alert-body">
          已颁发证书 {{pageData.all|placeholder}} 份，有效证书 {{pageData.effect|placeholder}} 份，已收回
          {{pageData.recovery|placeholder}} 份，已过期 {{pageData.expire}} 份
        </div>
      </div>
    </div> -->
    <div class="head">
      <!-- <div class="head-btn">
        <el-button size="small" @click="onEdit">编辑证书</el-button>
        <el-button size="small" @click="onOpenAwardDialog">颁发证书</el-button>
      </div> -->
      <ManageFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <div class="content-btn">
        <el-button size="small" @click="onMultipleRecovery">收回证书</el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" :selectable="onSelectable"></el-table-column>
        <el-table-column show-overflow-tooltip v-for="th in thead" :key="th.prop" :prop="th.prop"
          :label="th.label" :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button type="text" size="mini" @click="onView(row.id)">查看</el-button>
              <el-button type="text" size="mini" :disabled="row.status!==1" @click="onRecovery(row.id)">收回
              </el-button>
            </template>
            <!-- 用户名 -->
            <span v-else-if="th.prop==='nickname'">
              {{row.user|nickname|placeholder}}
            </span>
            <!-- 会内职务 -->
            <span v-else-if="th.prop==='user_position'">
              {{row.user|userPosition|placeholder}}
            </span>
            <!-- 其他 -->
            <template v-else>{{row[th.prop] | placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
    <!-- 颁发弹窗 -->
    <AwardDialog v-model="openAwardDialog" :certId="id" :certName="certName" :complete="getManageList" />
  </div>
</template>

<script>
import ManageFilter from "../components/manage/Filter";
import Pagination from "@/base/components/Default/Pagination";
import AwardDialog from "../components/manage/AwardDialog";
import { manageList, recoveryCert } from "../api/manage";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {
        cert_id:-1
      },
      thead: [
        { label: "ID", prop: "id", minWidth: 120, sort: "custom" },
        { label: "会员名称", prop: "nickname", minWidth: 120 },
        {
          label: "会内职务",
          prop: "user_position",
          minWidth: 180,
          tooltip: true,
        },
        { label: "证书名称", prop: "cert_name", minWidth: 120 },
        { label: "奖项名称", prop: "cert_prize_name", minWidth: 120 },
        { label: "证书编号", prop: "cert_number", minWidth: 120 },
        { label: "证书类型", prop: "type_text", minWidth: 120 },
        {
          label: "颁发时间",
          prop: "create_time",
          minWidth: 180,
          sort: "custom",
        },
        {
          label: "有效期至",
          prop: "expire_time",
          minWidth: 180,
          sort: "custom",
        },
        { label: "证书状态", prop: "status_text", minWidth: 120 },
        { label: "操作", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
      activeUserIds: [], //选中的id
      openAwardDialog: false, //是否显示颁发弹窗
      certName: "", //证书名字
    };
  },
  filters: {
    //用户名
    nickname(user = {}) {
      let name = "";
      if (user.hasOwnProperty("nickname")) {
        name = user.nickname;
      }
      return name;
    },
    //会内职务
    userPosition(user = {}) {
      let name = "";
      if (user.hasOwnProperty("userPosition")) {
        const arr = [];
        user.userPosition.forEach((item) => {
          arr.push(item.title_name);
        });
        name = arr.join("，");
      }
      return name;
    },
  },
  methods: {
    //获取列表数据
    getManageList(pageData) {
      this.loading = true;
      manageList(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          this.certName = data.cert_name;
          // console.log(data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选
    onFilter(data) {
      Object.assign(this.postData, data, { page: 1 });
      this.getManageList();
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { is_desc, order_by: prop }, { page: 1 });
      this.getManageList();
    },
    //table多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeUserIds = arr.map((item) => item.id);
      } else {
        this.activeUserIds = [];
      }
      Object.assign(this.postData, { export_ids: this.activeUserIds });
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getManageList();
    },
    //单个收回证书
    onRecovery(id, multiple = false) {
      let infoText = "确定要收回该会员的证书？";
      if (multiple) infoText = "确定要收回这些会员的证书？";
      this.$confirm(infoText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          const postData = { id };
          recoveryCert(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getManageList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多选回收
    onMultipleRecovery() {
      if (this.activeUserIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onRecovery(this.activeUserIds, true);
    },
    //无法回收的禁止多选选中
    onSelectable(row) {
      return row.status === 1;
    },
    //颁发
    onOpenAwardDialog() {
      this.openAwardDialog = true;
    },
    //编辑证书
    onEdit() {
      this.$router.push({ name: "CertEdit", query: { id: this.id } });
    },
    //查看
    onView(id) {
      this.$message.info("正在开发中...");
    },
  },
  created() {
    //证书id
    // Object.assign(this.postData, { cert_id: this.id }); 
    this.getManageList();
  },
  components: {
    ManageFilter,
    Pagination,
    AwardDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>
